:root {
  --c-bg: #00ff90;
  --c-bg-dark: #8bedf5;
  --c-fg: #f3008b;
  --pad: 20px;
  --pad-md: 15px;
  --section-gutter: 60px;
  --fs-h1: 36px;
  --fs-body: 16px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.is-hidden {
  display: none !important;
}

@media (min-width: 800px) {
  :root {
    --pad: 80px;
    --section-gutter: 120px;
    --pad-md: 50px;
    --fs-h1: 52px;
    --fs-body: 18px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "elza", sans-serif;
  font-size: var(--fs-body);
  margin: 0;
  padding: 0;
  background: var(--c-bg-dark);
  color: var(--c-fg);
  width: 100%;
  height: 100%;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65.5dvw;
    background: linear-gradient(to bottom, var(--c-bg) 50px, var(--c-bg-dark));
    z-index: 1;
  }
}

a {
  color: var(--c-fg);
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: white;
  transition: all 160ms ease-in;
}

a:hover,
a:focus {
  text-decoration-color: var(--c-fg);
}

@media screen and (min-width: 800px) {
  body {
    &:after {
      height: 33dvw;
    }
  }
}

@media screen and (min-width: 1400px) {
  body {
    &:after {
      height: 700px;
      background: linear-gradient(
        to bottom,
        var(--c-bg) 200px,
        var(--c-bg-dark)
      );
    }
  }
}

.l-site-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: var(--pad);
  position: relative;
  z-index: 100;
  overflow: hidden;
}

h2 {
  font-size: 2em;
  margin: 0;
  text-transform: uppercase;
}

h3 {
  text-transform: uppercase;
}

.l-section {
  &--media {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-left: calc(-1 * var(--pad));
    margin-right: calc(-1 * var(--pad));

    img,
    picture {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
    }

    .cta-button {
      position: absolute;
    }
  }
  &--copy {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &-heading {
      margin-bottom: -1em;
      margin-left: calc(-1 * var(--pad));
      position: relative;
      z-index: 1;
      font-size: var(--fs-h1);
      text-transform: uppercase;
    }

    &-text {
      padding: var(--pad-md) 0;
      padding-top: calc(3 * var(--pad-md));
      a {
        display: inline-block;
      }
      * {
        margin: 0;
      }
      * + * {
        margin-top: 0.8em;
      }
    }

    p {
      line-height: 1.4;
    }
  }
  + .l-section {
    margin-top: var(--section-gutter);
  }
}

@media screen and (min-width: 800px) {
  .l-section {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    align-content: center;
    &--copy {
      grid-column: 1 / 6;
      grid-row: 1;
      margin-top: calc(-1 * var(--fs-h1));
      &-text {
        padding: var(--pad-md);
        padding-top: calc(1.5 * var(--pad-md));
        background: var(--c-bg);
      }
      &-heading {
        padding-left: 0;
        margin-left: -0.5em;
      }
    }
    &--media {
      grid-column: 5 / 13;
      grid-row: 1;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.light {
  color: white;
}

.l-fold {
  margin-bottom: calc(var(--section-gutter) * 1.5);
  &__logo {
    position: relative;
    z-index: 3;

    img {
      width: 110%;
      height: auto;
    }
  }
  &__video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-top: calc(
      -1 * (1.32 * 100vw * 0.325)
    ); // Make spaceing between video and bottom of THINGS match color split
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: 800px) {
  .l-fold {
    display: grid;
    position: relative;
    margin-bottom: 50px;
    &__logo {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 50%;
      img {
        width: 100%;
      }
    }
    &__video {
      position: absolute;
      z-index: 2;
      width: 68%;
      height: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      right: 0;
      video {
        height: auto;
      }
    }
  }
}

.site-link {
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-thickness: 6px;
  text-decoration-color: white;
  color: var(--c-fg);
  padding-bottom: 6px;
  transition: all 160ms ease-in;
}

.site-link:hover,
.site-link:focus {
  text-decoration-color: var(--c-fg);
}

.btn {
  transition: all 160ms ease-in;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--c-fg);
  background-color: white;
  display: inline-block;
  padding: 10px 20px;
  &:hover,
  &:focus {
    background: var(--c-fg);
    color: white;
  }
  &:focus {
    text-decoration: underline;
  }
}

.btn-group {
  display: flex;
  gap: 20px;
}

.l-footer {
  background: linear-gradient(to bottom, var(--c-bg-dark), var(--c-bg) 25%);
  margin-top: var(--section-gutter);
  &__inner {
    padding: var(--pad);
    padding-top: 0;
    max-width: 1400px;
    margin: 0 auto;
  }
  &__heading {
    top: -1em;
    margin-bottom: -1em;
    margin-left: -0.5em;
    position: relative;
    z-index: 1;
    font-size: var(--fs-h1);
  }
  &__columns {
    &-single {
      p {
        margin: 0.5em 0;
      }
      &-section {
        margin: 1.2em 0;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .l-footer {
    &__columns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--pad);
    }
  }
}

.sponsors {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, auto);
  img {
    height: 80px;
    width: auto;
    display: block;
  }
}

@media screen and (min-width: 800px) {
  .sponsors {
    width: 50%;
  }
}

@media screen and (min-width: 1100px) {
  .sponsors {
    grid-template-columns: repeat(4, auto);
    width: 100%;
  }
}
