:root {
  --c-bg: #00ff90;
  --c-bg-dark: #8bedf5;
  --c-fg: #f3008b;
  --pad: 20px;
  --pad-md: 15px;
  --section-gutter: 60px;
  --fs-h1: 36px;
  --fs-body: 16px;
}

*, :before, :after {
  box-sizing: border-box;
}

.is-hidden {
  display: none !important;
}

@media (width >= 800px) {
  :root {
    --pad: 80px;
    --section-gutter: 120px;
    --pad-md: 50px;
    --fs-h1: 52px;
    --fs-body: 18px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: elza, sans-serif;
  font-size: var(--fs-body);
  background: var(--c-bg-dark);
  color: var(--c-fg);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

body:after {
  content: "";
  background: linear-gradient(to bottom, var(--c-bg) 50px, var(--c-bg-dark));
  z-index: 1;
  width: 100%;
  height: 65.5dvw;
  position: absolute;
  top: 0;
  left: 0;
}

a {
  color: var(--c-fg);
  text-decoration: underline 2px #fff;
  transition: all .16s ease-in;
}

a:hover, a:focus {
  -webkit-text-decoration-color: var(--c-fg);
  text-decoration-color: var(--c-fg);
}

@media screen and (width >= 800px) {
  body:after {
    height: 33dvw;
  }
}

@media screen and (width >= 1400px) {
  body:after {
    background: linear-gradient(to bottom, var(--c-bg) 200px, var(--c-bg-dark));
    height: 700px;
  }
}

.l-site-container {
  width: 100%;
  max-width: 1400px;
  padding: var(--pad);
  z-index: 100;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

h2 {
  text-transform: uppercase;
  margin: 0;
  font-size: 2em;
}

h3 {
  text-transform: uppercase;
}

.l-section--media {
  margin-left: calc(-1 * var(--pad));
  margin-right: calc(-1 * var(--pad));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.l-section--media img, .l-section--media picture {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

.l-section--media .cta-button {
  position: absolute;
}

.l-section--copy {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.l-section--copy-heading {
  margin-bottom: -1em;
  margin-left: calc(-1 * var(--pad));
  z-index: 1;
  font-size: var(--fs-h1);
  text-transform: uppercase;
  position: relative;
}

.l-section--copy-text {
  padding: var(--pad-md) 0;
  padding-top: calc(3 * var(--pad-md));
}

.l-section--copy-text a {
  display: inline-block;
}

.l-section--copy-text * {
  margin: 0;
}

.l-section--copy-text * + * {
  margin-top: .8em;
}

.l-section--copy p {
  line-height: 1.4;
}

.l-section + .l-section {
  margin-top: var(--section-gutter);
}

@media screen and (width >= 800px) {
  .l-section {
    grid-template-columns: repeat(12, 1fr);
    align-content: center;
    display: grid;
  }

  .l-section--copy {
    margin-top: calc(-1 * var(--fs-h1));
    grid-area: 1 / 1 / auto / 6;
  }

  .l-section--copy-text {
    padding: var(--pad-md);
    padding-top: calc(1.5 * var(--pad-md));
    background: var(--c-bg);
  }

  .l-section--copy-heading {
    margin-left: -.5em;
    padding-left: 0;
  }

  .l-section--media {
    grid-area: 1 / 5 / auto / 13;
    margin-left: 0;
    margin-right: 0;
  }
}

.light {
  color: #fff;
}

.l-fold {
  margin-bottom: calc(var(--section-gutter) * 1.5);
}

.l-fold__logo {
  z-index: 3;
  position: relative;
}

.l-fold__logo img {
  width: 110%;
  height: auto;
}

.l-fold__video {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: -42.9vw;
  display: flex;
}

.l-fold__video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (width >= 800px) {
  .l-fold {
    margin-bottom: 50px;
    display: grid;
    position: relative;
  }

  .l-fold__logo {
    justify-content: center;
    align-items: flex-end;
    width: 50%;
    display: flex;
  }

  .l-fold__logo img {
    width: 100%;
  }

  .l-fold__video {
    z-index: 2;
    width: 68%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    right: 0;
  }

  .l-fold__video video {
    height: auto;
  }
}

.site-link {
  text-transform: uppercase;
  color: var(--c-fg);
  padding-bottom: 6px;
  font-weight: bold;
  text-decoration: underline 6px #fff;
  transition: all .16s ease-in;
}

.site-link:hover, .site-link:focus {
  -webkit-text-decoration-color: var(--c-fg);
  text-decoration-color: var(--c-fg);
}

.btn {
  text-transform: uppercase;
  color: var(--c-fg);
  background-color: #fff;
  padding: 10px 20px;
  font-weight: bold;
  text-decoration: none;
  transition: all .16s ease-in;
  display: inline-block;
}

.btn:hover, .btn:focus {
  background: var(--c-fg);
  color: #fff;
}

.btn:focus {
  text-decoration: underline;
}

.btn-group {
  gap: 20px;
  display: flex;
}

.l-footer {
  background: linear-gradient(to bottom, var(--c-bg-dark), var(--c-bg) 25%);
  margin-top: var(--section-gutter);
}

.l-footer__inner {
  padding: var(--pad);
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 0;
}

.l-footer__heading {
  z-index: 1;
  font-size: var(--fs-h1);
  margin-bottom: -1em;
  margin-left: -.5em;
  position: relative;
  top: -1em;
}

.l-footer__columns-single p {
  margin: .5em 0;
}

.l-footer__columns-single-section {
  margin: 1.2em 0;
}

@media screen and (width >= 800px) {
  .l-footer__columns {
    gap: var(--pad);
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.sponsors {
  grid-template-columns: repeat(2, auto);
  gap: 15px;
  display: grid;
}

.sponsors img {
  width: auto;
  height: 80px;
  display: block;
}

@media screen and (width >= 800px) {
  .sponsors {
    width: 50%;
  }
}

@media screen and (width >= 1100px) {
  .sponsors {
    grid-template-columns: repeat(4, auto);
    width: 100%;
  }
}
/*# sourceMappingURL=index.ea299575.css.map */
